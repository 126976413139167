@import url('https://fonts.googleapis.com/css2?family=Anton&family=Jost&family=Open+Sans&family=Playfair+Display:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Jost&family=Montserrat&family=Open+Sans&family=Playfair+Display:wght@400;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes scroll {
    from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-9410px);
      }
  }
  
  .animate {
    animation: scroll 60s linear infinite;
  }

  .projectLanding::before {    
    content: "";
    background-image: url("./assets/ourprojects.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    
   
  }
  

  .child{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }